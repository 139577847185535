import { SharedoFetch } from '@sharedo/mobile-core'

function getTimeEntries(sharedoId, rpp, p) {
    // TODO public API
    return SharedoFetch.post("/api/timeentries/finder", {
        sharedoId: sharedoId,
        rowsPerPage: rpp,
        startPage: p || 1,
    });
}

function getTimeConfig() {
    return SharedoFetch.get("/api/timeentries/config");
}

function getTimeCodes(sharedoId) {
    // TODO handle striping
    return SharedoFetch.get(`/api/timecodes/forsharedo/${sharedoId}`);
}

function getTimeCodeCategories() {
    // TODO public API
    return SharedoFetch.get("/api/v2/public/time/categories");
}

const getTimeEntry = (id) => SharedoFetch.get(`/api/v2/public/time/entry/${id}`);

function createTimeEntry(entry) {
    return SharedoFetch.post(`/api/v2/public/time/entry`, entry);
}

function updateTimeEntry(id, entry) {
    const endPoint = {
        url: `/api/v2/public/time/entry/${id}`,
        responseType: "text"
    }; // HACK:  To get around the fact that the API reponse doesn't have a body even though an HTTP 200 is returned instead of a 204.

    return SharedoFetch.put(endPoint, entry);
}

function submitTimeEntry(id) {
    const endPoint = {
        url: `/api/time/entry/${id}/state/submit`,
        responseType: "text"
    };

    return SharedoFetch.post(endPoint);
}

function deleteTimeEntry(sharedoId, id) {
    return SharedoFetch.delete({ url: `/api/v1/public/workitem/${sharedoId}/time/${id}`, responseType: "text" });
}

const getCapture = (categorySystemName, workItemId) => SharedoFetch.get(`/api/v2/public/time/capture/${encodeURIComponent(categorySystemName)}/${workItemId}`)

export default {
    getTimeEntries,
    getTimeConfig,
    getTimeCodes,
    getTimeCodeCategories,
    getCapture,
    getTimeEntry,
    createTimeEntry,
    updateTimeEntry,
    submitTimeEntry,
    deleteTimeEntry,
};
