<template>
    <v-card class="time-entry-form">
        <div v-if="loaded">
            <v-toolbar flat outlined>
                <v-icon @click="close()">mdi-close</v-icon>

                <v-spacer />

                <v-toolbar-title
                    >{{ isNew ? "New" : canEdit ? "Edit" : "View" }} time
                    entry</v-toolbar-title
                >

                <v-spacer />

                <v-btn
                    @click="save()"
                    small
                    text
                    v-if="canEdit"
                    :disabled="!isValid"
                    >Save</v-btn
                >
            </v-toolbar>

            <v-banner-sharedo
                icon="mdi-alert"
                icon-color="warning"
                v-if="
                    (!timeCodeCategoryIdLocal || segmentsIncomplete) &&
                    inDraftState
                "
            >
                Time code required to submit.
            </v-banner-sharedo>

            <v-banner-sharedo
                icon="mdi-upload"
                icon-color="info"
                v-if="isReadyToSubmit"
            >
                Ready to submit.
                <template v-slot:actions>
                    <v-btn text @click="submit()">Submit</v-btn>
                </template>
            </v-banner-sharedo>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-select
                            label="Category"
                            v-model="timeCodeCategoryIdLocal"
                            v-bind:items="timeCodeCategories"
                            item-text="name"
                            item-value="systemName"
                            single-line
                            :disabled="!canEdit"
                            :error-messages="categoryValidation"
                            @change="timeCodeChanged"
                        ></v-select>
                    </v-row>
                    <v-row
                        v-show="timeCodeCategoryIdLocal"
                        v-for="segment in segments"
                        :key="segment.id"
                    >
                        <segment-selector
                            v-if="segment.captureType === 'TimeCodeSet'"
                            v-model="segment.value"
                            :codes="segment.timeCodes"
                            :error="
                                segment.isMandatoryForEntry && !segment.value
                            "
                        />
                        <v-textarea
                            v-else
                            label="Memo"
                            v-model="segment.value"
                            type="text"
                            rows="4"
                            auto-grow
                            :error="
                                segment.isMandatoryForEntry && !segment.value
                            "
                        />
                    </v-row>
                    <v-row>
                        <v-text-field
                            label="Start"
                            v-model="startInput"
                            required
                            type="datetime-local"
                            placeholder="dd/mm/yyyy hh:mm"
                            :disabled="!canEdit"
                            :error="!!startValidation"
                        ></v-text-field>
                        <span
                            class="error--text min-w-full"
                            v-if="startValidation"
                            >{{ startValidation }}</span
                        >
                    </v-row>
                    <v-row>
                        <v-text-field
                            label="End"
                            v-model="endInput"
                            required
                            type="datetime-local"
                            placeholder="dd/mm/yyyy hh:mm"
                            :disabled="!canEdit"
                            :error="!!endValidation"
                        ></v-text-field>
                        <span
                            class="error--text min-w-full"
                            v-if="endValidation"
                            >{{ endValidation }}</span
                        >
                    </v-row>
                    <v-row class="mb-1">
                        <v-text-field
                            label="Duration"
                            type="text"
                            v-model="duration"
                            v-on:blur="onDurationBlur"
                            placeholder="e.g. 1h 30m"
                            :disabled="!canEdit"
                            :error="!!durationValidation"
                        ></v-text-field>
                        <span
                            class="error--text min-w-full"
                            v-if="durationValidation"
                            >{{ durationValidation }}</span
                        >
                    </v-row>
                    <v-row>
                        <v-text-field
                            label="Owner"
                            type="text"
                            v-model="timekeeperName"
                            :disabled="true"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-textarea
                            label="Narrative"
                            v-model="billingNotesLocal"
                            type="text"
                            rows="4"
                            auto-grow
                            :disabled="!canEdit"
                        ></v-textarea>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-row class="mt-1" v-if="canDelete">
                <v-col class="mx-6 text-center">
                    <v-btn
                        block
                        outlined
                        class="error--text"
                        @click="confirmDelete()"
                    >
                        Delete
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-else class="skeleton-container">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </div>
    </v-card>
</template>

<script>
import timeEntriesAgent from "./timeEntriesAgent.js";
import { SharedoProfile } from "@sharedo/mobile-core";
import SegmentSelector from "./SegmentSelector";

export default {
    components: { SegmentSelector },
    props: {
        id: String,
        sharedoId: String,
        canEdit: Boolean,
        stateSystemName: String,
        startDateTime: Date,
        endDateTime: Date,
        durationSeconds: Number,
        timeCodeCategoryId: String,
        timeCode: String,
        timeKeeperId: String,
        timekeeperName: String,
        billingNotes: String,
        odsId: String,
    },

    data: function () {
        return {
            startLocal: this.startDateTime,
            endLocal: this.endDateTime,
            durationSecondsLocal: this.durationSeconds,
            timeCodeCategoryIdLocal: "",
            timeCodeLocal: this.timeCode,
            billingNotesLocal: this.billingNotes,
            timeCodeCategories: [],
            timeCodes: [],
            segments: [],
            timeConfig: null,
            loaded: false,

            ui: {
                durationInput: null,
                suspend: false,
            }
        };
    },

    computed: {
        isNew() {
            return !this.id;
        },

        canDelete() {
            return (
                this.canEdit &&
                !this.isNew &&
                SharedoProfile.profile.globalPermissions.some(
                    (permission) => permission === "core.time.delete"
                )
            );
        },

        startInput: {
            get: function () {
                return this.startLocal
                    ? this.$moment(this.startLocal).format("YYYY-MM-DDTHH:mm")
                    : null;
            },
            set: function (newValue) {
                // TODO move end to keep duration?
                if (newValue)
                    this.startLocal = this.$moment(newValue).utc().format();
            },
        },

        endInput: {
            get: function () {
                return this.endLocal
                    ? this.$moment(this.endLocal).format("YYYY-MM-DDTHH:mm")
                    : null;
            },
            set: function (newValue) {
                // TODO move start backwards? or validate?
                if (newValue)
                    this.endLocal = this.$moment(newValue).utc().format();
            },
        },

        startValidation() {
            return !this.startLocal ? "Start required" : null;
        },

        endValidation() {
            return !this.endLocal ? "End required" : null;
        },

        durationValidation() {
            if (this.startValidation || this.endValidation) return null;

            if (!this.durationSecondsLocal) return "Duration must be valid and non-zero";

            if (this.isNew)
            {
                var config = this.timeConfig;
                if (!config) return null;
                
                var earliestStart = this.$moment().add(config.startTimeMaxDaysAgo * -1, "days");
                var latestEnd = this.$moment().add(config.endTimeMaxDaysInFuture, "days");
                var maxHours = config.maxHours;

                var startMoment = this.$moment(this.startLocal);
                var endMoment = this.$moment(this.endLocal);

                if (startMoment < earliestStart ) return "Start must be within the last " + config.startTimeMaxDaysAgo + " days";
                if (startMoment > latestEnd) return "Start must be within the next " + config.endTimeMaxDaysInFuture + " days";
                if (maxHours > 0)
                {
                    if (this.durationSecondsLocal > (maxHours * 3600)) return "Duration exceeds maximum allowed - must be at most " + maxHours + " hours";
                }

                if (endMoment > latestEnd) return "End must be within the next " + config.endTimeMaxDaysInFuture + " days";
            }

            return null;
        },

        categoryValidation: function () {
            if (!this.timeCodeCategoryIdLocal) {
                return "Choose a category";
            }

            return null;
        },

        segmentsIncomplete: function () {
            return this.segments.some(
                (segment) => segment.isMandatoryForEntry && !segment.value
            );
        },

        isValid() {
            var errors = 0;
            if (this.startValidation) errors++;
            if (this.endValidation) errors++;
            if (this.durationValidation) errors++;
            if (this.categoryValidation) errors++;

            if (this.segmentsIncomplete) {
                errors++;
            }

            return errors === 0;
        },

        duration: {
            get: function () {
                if (this.ui.durationInput) return this.ui.durationInput;

                return this.parseSecondsToDuration(this.durationSecondsLocal);
            },
            set: function (v) {
                this.ui.durationInput = v;

                if (!v)
                {
                    this.durationSecondsLocal = 0;
                    return;
                }

                this.durationSecondsLocal = this.parseDurationToSeconds(v);
            },
        },

        availableTimeCodes() {
            if (!this.timeCodeCategoryIdLocal) return this.timeCodes;

            return this.timeCodes.filter((x) => {
                return x.categoryId === this.timeCodeCategoryIdLocal;
            });
        },

        isReadyToSubmit() {
            return (
                this.startLocal &&
                this.endLocal &&
                this.timeCodeLocal &&
                this.inDraftState &&
                this.isValid
            );
        },

        inDraftState() {
            return ["draft", "revising"].indexOf(this.stateSystemName) !== -1;
        },
    },

    watch: {
        startLocal() {
            if (this.ui.suspend) return;

            // When start date/time changes, recalculate the end date as start + duration
            var newEndDate = this.offsetDateTimeByDuration(this.startLocal);
            if (newEndDate)
            {
                this.suspendChanges(() => { this.endLocal = newEndDate; });
            }
        },

        durationSecondsLocal() {
            if (this.ui.suspend) return;

            // When duration changes, recalculate the end date as start + duration
            var newEndDate = this.offsetDateTimeByDuration(this.startLocal);
            if (newEndDate)
            {
                this.suspendChanges(() => { this.endLocal = newEndDate; });
            }
        },

        endLocal() {
            if (this.ui.suspend) return;
                    
            // When end date/time changes....
            // If it's now before start date/time, move start date/time backwards from end date by duration
            // If it's still after start date/time, re-calculate the duration
            var startDateTimeValue = this.startLocal;
            var start = this.$moment(startDateTimeValue);
            var endDateTimeValue = this.endLocal;
            var end = this.$moment(endDateTimeValue);

            var diff = end.diff(start, "seconds");
            if (!start.isValid() || diff < 0)
            {
                // End has moved to before start - move start backwards from end by duration
                var newStartDate = this.offsetDateTimeByDuration(endDateTimeValue, -1);
                if (newStartDate)
                {
                    this.suspendChanges(() => { this.startLocal = newStartDate; });
                }
            }
            else
            {
                this.ui.durationInput = null;
                this.suspendChanges(() => { this.durationSecondsLocal = diff; });
            }
        },
    },

    async mounted() {
        const vm = this;

        try {
            this.timeCodeCategories =
                await timeEntriesAgent.getTimeCodeCategories();
                
            this.timeConfig =
                await timeEntriesAgent.getTimeConfig();

            // Defaults for new time entry
            if (this.isNew) {
                var now = this.$moment().startOf('minute');
                this.startLocal = now;
                this.endLocal = now.clone();
            } else {
                const entry = await timeEntriesAgent.getTimeEntry(this.id);

                this.timeCodeCategoryIdLocal = entry.timeCodeCategorySystemName;

                await this.loadCapture();

                entry.segments.forEach((segment) => {
                    const matchedSegment = vm.segments.find(
                        (s) => s.id === segment.id
                    );

                    if (matchedSegment) {
                        if (matchedSegment.captureType === "TimeCodeSet") {
                            matchedSegment.value = segment.timeCodeId;
                        } else {
                            matchedSegment.value = segment.segmentValue;
                        }
                    }
                });
            }

            vm.loaded = true;
        } catch (error) {
            console.log(error);
        }
    },

    methods: {
        timeCodeChanged: async function (newValue) {
            if (newValue) {
                await this.loadCapture();
            }
        },
        loadCapture: async function () {
            try {
                const capture = await timeEntriesAgent.getCapture(
                    this.timeCodeCategoryIdLocal,
                    this.sharedoId
                );

                this.segments = capture.segments;
            } catch (error) {
                console.log(error);
            }
        },
        async submit() {
            try {
                await this.save(true);

                const l = this.$coreUi.loading();

                await timeEntriesAgent.submitTimeEntry(this.id);

                l.dismiss();
                this.close(true);
            } catch (error) {
                console.log(error);
            }
        },

        async save(suppressClose) {
            try {
                var l = this.$coreUi.loading();

                var entry = {
                    sharedoId: this.sharedoId,
                    durationSeconds: this.durationSecondsLocal,
                    startDateTime: this.startLocal,
                    endDateTime: this.endLocal,
                    billingNotes: this.billingNotesLocal,
                    regenerateBillingNotes: false, // HACK
                    createdBySystem: false,
                    odsId: this.odsId,
                    timeCodeCategorySystemName: this.timeCodeCategoryIdLocal,
                    segments: this.segments.map((segment) => ({
                        id: segment.id,
                        segmentValue:
                            segment.captureType === "Memo"
                                ? segment.value
                                : null,
                        timeCodeId:
                            segment.captureType === "TimeCodeSet"
                                ? segment.value
                                : null,
                    })),
                };

                var saveTimeEntry = this.isNew
                    ? timeEntriesAgent.createTimeEntry(entry)
                    : timeEntriesAgent.updateTimeEntry(this.id, entry);

                await saveTimeEntry;

                l.dismiss();
                if (!suppressClose) {
                    this.close(true);
                }
            } catch (error) {
                console.log(error);
            }
        },

        confirmDelete() {
            this.$coreUi.messageBox({
                title: `Delete time entry`,
                message: "Are you sure?",
                btns: [
                    { text: "Cancel" },
                    {
                        text: "Delete",
                        color: "error",
                        handler: () => this.delete(),
                    },
                ],
            });
        },

        delete() {
            var l = this.$coreUi.loading();

            timeEntriesAgent
                .deleteTimeEntry(this.sharedoId, this.id)
                .then(() => {
                    l.dismiss();
                    this.close(true);
                })
                .catch(console.error);
        },

        close(result) {
            this.$emit("close", result);
        },

        
        onDurationBlur() {
            // When duration loses focus, fix up the content of it
            this.ui.durationInput = null;
        },
            
        parseDurationToSeconds(v) {
            var totalSeconds = 0;
            var re = /(\d+)[\s,]*([a-zA-Z]+)/g;

            var matchedCount = 0;
            var matches = re.exec(v);
            while (matches)
            {
                matchedCount++;
                // Validation
                // Each match should be at least 3 long - eg. 32m would be ["32m", "32", "m"]
                // index [1] should be the number, index [2] should be the unit
                var validPart = true;
                if (matches.length < 3) validPart = false;
                if (matches.length > 2 && isNaN(matches[1])) validPart = false;

                // Parse
                if (validPart)
                {
                    var value = parseInt(matches[1]);
                    var unit = matches[2].toLowerCase();

                    // Special handling of units that moment.duration doesn't understand
                    if (unit === "min") unit = "m";
                    if (unit === "mins") unit = "m";
                    
                    var seconds = this.$moment.duration(value, unit).asSeconds();
                    totalSeconds += seconds;
                    //console.log("" + value + " " + unit + " parsed to " + seconds + " seconds - total is now " + totalSeconds);
                }

                // Next part
                matches = re.exec(v);
            }

            if (matchedCount === 0)
            {
                // None of the inputs matched things like 32m 4s 8 hours etc... just try to parse it as minutes
                var parsedAsSeconds = parseInt(v);
                if (!isNaN(parsedAsSeconds)) totalSeconds = (parsedAsSeconds * 60);
            }

            return totalSeconds;
        },

        parseSecondsToDuration(v) {
            var duration = this.$moment.duration(v, "seconds");

            var result = "";

            // Treat hours special - there could also be days, months and years, we want it all in hours
            var hours = Math.floor(duration.asHours());
            if (hours) result += hours + "h ";
            if (duration.minutes()) result += duration.minutes() + "m ";
            if (duration.seconds()) result += duration.seconds() + "s ";

            return result;
        },

        offsetDateTimeByDuration(dateString, direction) {
            if (direction === undefined) direction = 1;
            direction = Math.sign(direction);

            if( !dateString ) return null;

            var date = this.$moment(dateString);
            if( !date.isValid() ) return null;

            var duration = parseInt(this.durationSecondsLocal);
            if (isNaN(duration)) duration = 0;

            if (duration > 0) date.add(duration * direction, "seconds");

            return date;
        },
        
        suspendChanges(actions) {
            this.ui.suspend = true;
            actions();
            setTimeout(() => { this.ui.suspend = false; });
        },

    },
};
</script>
<style lang="scss" scoped>
.skeleton-container {
    padding: 20px;
}
</style>