<template>
    <v-container class="min-h-full">
        <VTopToolbar title="Time Entries" :showBack="true">

            <template slot="right">
                 <v-icon v-if="hasCreatePermission" @click="createTimeEntry()">mdi-plus</v-icon>
            </template>

        </VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader
                type="image, text, image, text, image"
            ></v-skeleton-loader>
        </div>

        <h4 class="grey-text font-weight-light mt-3 mb-5" v-if="!loading && !timeEntries.length">
            There are no time entries.
        </h4>

        <div
                v-show="!loading"
                v-for="(item, index) in timeEntries"
                :key="item.id"
        >
            <v-subheader v-if="!sameDayAs(item, timeEntries[index - 1])" class="px-1">{{item.startDay}}</v-subheader>

            <v-card class="mx-auto mb-3 no-focus-on-click" outlined @click.stop="editTimeEntryDetails(item)">
                <div class="d-flex">
                    <div class="flex-grow-1 min-w-0">
                            
                        <div class="d-flex align-start flex-nowrap mb-3">
                            <v-card-title class="flex-grow-0">
                                <v-icon left :class="item.cssClass + '--text'">{{item.icon}}</v-icon>
                            </v-card-title>
                            <div class="flex-grow-1 min-w-0">

                                <h3 class="font-weight-bold grey--text text--darken-3 mt-3">
                                    {{item.start.format("HH:mm")}}
                                    &ndash;
                                    {{item.end.format("HH:mm")}}<span v-if="item.startDay !== item.endDay" class="day-indicator">*</span>

                                    <span class="grey--text duration">
                                        (<span v-if="item.duration.hours() !== 0">{{item.duration.hours()}}<span class="small">h </span>
                                        </span>{{item.duration.minutes()}}<span class="small">m</span>)
                                    </span>
                                </h3>
                                
                                <div class="data text-truncate" v-if="item.timeCodeCategoryName">{{item.timeCodeCategoryName}}</div>
                                <div class="data text-truncate" :class="{ 'missing': !item.timeCodeDescription }">{{item.timeCodeDescription || "No time code"}}</div>
                                
                                <div class="data text-truncate mt-1 font-italic secondary--text text--darken-2" v-if="item.billingNotes">{{'"' + item.billingNotes + '"'}}</div>

                                <div class="data text-truncate mt-1">
                                    <v-icon left small style="vertical-align: inherit;">fa-male</v-icon>
                                    <span>{{item.timekeeperName}}</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="flex-grow-0 d-flex">
                        <v-icon large color="grey lighten-2">mdi-chevron-right</v-icon>
                    </div>
                </div>
                <v-divider></v-divider>
            </v-card>

        </div>

    </v-container>
</template>

  
<script>
import timeEntriesAgent from "./timeEntriesAgent.js";
import TimeEntryDetailForm from "./TimeEntryDetailForm.vue";
import { SharedoProfile } from '@sharedo/mobile-core';

const rpp = 99;

// TODO move component to core?
export default {
    components: {
        TimeEntryDetailForm,
    },
    data() {
        return {
            sharedoId: null,
            loading: true,
            timeEntries: [],
            hasCreatePermission: false,
        };
    },
    mounted() {
        this.sharedoId = this.$route.params.sharedoId;

        this.hasCreatePermission = SharedoProfile.profile.globalPermissions.indexOf("core.time.create") !== -1;

        this.refresh();
    },
    methods: {
        loadPage() {
            return timeEntriesAgent.getTimeEntries(this.sharedoId, rpp, 1).then(data => {
                this.loading = false;

                var models = data.rows.map(t => {
                    var start = this.$moment(t.startDateTime);
                    var end = this.$moment(t.endDateTime);
                    
                    var duration = this.duration(start, end);

                    return {
                        id: t.id,
                        icon: this.getIcon(t.stateSystemName),
                        cssClass: this.getCssClass(t.stateSystemName),
                        startDateTime: t.startDateTime,
                        endDateTime: t.endDateTime,
                        start: start,
                        end: end,
                        duration: duration,
                        durationSeconds: t.durationSeconds,
                        startDay: this.toCalendarDate(start),
                        endDay: this.toCalendarDate(end),
                        odsId: t.odsId,
                        state: t.stateSystemName,
                        timekeeperName: t.timekeeperName,
                        timeCode: t.timeCode,
                        timeCodeDescription: t.timeCodeDescription,
                        timeCodeCategoryId: t.timeCodeCategoryId,
                        timeCodeCategoryName: t.timeCodeCategoryName,
                        billingNotes: t.billingNotes,
                    };
                });

                this.timeEntries.push.apply(this.timeEntries, models);
            })
            .catch(console.error);
        },

        refresh() {
            this.timeEntries = [];
            this.loading = true;
                
            this.loadPage()
                .catch(console.error);            
        },

        duration (from, to) {
            var start = this.$moment(from);
            var end = this.$moment(to);

            var ms = Math.max(end.diff(start), 0);
            
            var roundUpMin = Math.ceil(ms / (1000*60)) * (1000*60);

            return this.$moment.duration(roundUpMin);
        },

        toCalendarDate(date) {
            return date.calendar(null, {
                lastDay : '[Yesterday]',
                sameDay : '[Today]',
                lastWeek : 'dddd, DD MMMM',
                sameElse : 'L'
            });
        },

        isEditableState(state) {
            return state === "draft" || state === "revising";
        },

        getIcon(state) {
            if (this.isEditableState(state))
                return "mdi-file-document-edit-outline";
            else if (state === "reversed")
                return "mdi-clock-remove-outline";
            else // ready-to-submit, submitted
                return "mdi-check";
        },

        getCssClass(state) {
            if (state === "ready-to-submit" || state === "submitted")
                return "success";
            else if (state === "reversed")
                return "grey";
            else // draft, revising
                return "warning";
        },

        sameDayAs(timeEntry, previous) {
            return previous && timeEntry.startDay === previous.startDay;
        },
        
        createTimeEntry () {
            this.$coreUi.dialog(TimeEntryDetailForm,
                {
                    sharedoId: this.sharedoId,
                    timekeeperName: SharedoProfile.profile.name,
                    canEdit: true,
                    stateSystemName: "draft",
                    odsId: SharedoProfile.profile.userId,
                },
                {
                    closing: result => {
                        if (result) this.refresh();
                    }
                });

        },

        editTimeEntryDetails (timeEntry) {
            this.$coreUi.dialog(TimeEntryDetailForm,
                {
                    sharedoId: this.sharedoId,
                    id: timeEntry.id,
                    startDateTime: new Date(timeEntry.startDateTime),
                    endDateTime: new Date(timeEntry.endDateTime),
                    durationSeconds: timeEntry.durationSeconds,
                    timeCodeCategoryId: timeEntry.timeCodeCategoryId,
                    timeCode: timeEntry.timeCode,
                    timekeeperName: timeEntry.timekeeperName,
                    billingNotes: timeEntry.billingNotes,
                    canEdit: this.isEditableState(timeEntry.state) && timeEntry.odsId === SharedoProfile.profile.userId,
                    stateSystemName: timeEntry.state,
                    odsId: timeEntry.odsId,
                },
                {
                    closing: result => {
                        if (result) this.refresh();
                    }
                });
        },
    },
};
</script>

<style scoped>
.day-indicator {
    color: var(--v-error-lighten1);
}
.duration .small {
    font-size: 70%;
}
.data {
    font-size: 14px;
    color: var(--v-grey-darken3);
}
.missing {
    font-style: italic;
    color: var(--v-grey-darken1);
}
</style>